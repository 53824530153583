<template>
<div>
  <v-row :no-gutters="$vuetify.breakpoint.xs">
    <v-col cols="12">
      <v-card
        elevation="2"
        outlined
      >
      <v-card-title>
        {{ nuevo ? 'Saldos a Favor' : 'Saldos Utilizados'}}
      </v-card-title>
      <v-card-text>
        <v-row v-if="nuevo">
          <!-- Data dinámico -->
          <v-col cols="12">
            <v-simple-table class="cebra" v-if="saldos_a_favor.length > 0">
              <!-- <template v-slot:default> -->
                <thead >
                  <tr>
                    <th scope="col" class="text-center"
                        v-for="item in saldos_a_favor"  :key="item.entidad_id" >  {{ item.entidad_nombre }}  </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td scope="col" class="text-center"
                      v-for="item in saldos_a_favor" :key="item.entidad_id">{{ formatMoney(item.saldo) }}</td>
                  </tr>
                 <tr class="font-weight-bold">
                    <th v-if="saldos_a_favor.length > 1" :colspan="saldos_a_favor.length-1"> </th>
                    <th style="font-size: 1em" class="text-center" justify="end"> Total disponible: {{ formatMoney(retornarSaldoDisponible) }}</th>
                  </tr>
                </tbody>
            </v-simple-table>
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center" v-if="nuevo">
          <v-col cols="6" sm="4" md="2"  class="py-4" >
            <TextFieldMoneyVue
              label="Importe"
              v-model="filtro.importe"
              v-bind:properties="{
                'hide-details': true,
                prefix: '$',
                type: 'number',
                clearable: true,
              }"
            />
          </v-col>
          <v-col cols="6" sm="4" md="4" class="py-4" >
            <v-autocomplete
              label="Origen Saldos"
              v-model="filtro.entidad_id"
              item-text="nombre"
              item-value="codigo"
              :items="entidades_deposito"
              validate-on-blur
              outlined
              hide-details
              dense
            ></v-autocomplete>
          </v-col>
          <v-col cols="6" sm="4" md="4" class="d-flex justify-center">
            <v-btn
              color="info"
              title="Agregar saldo"
              @click="agregarImporteSaldo()"
            >Agregar</v-btn>
            <v-btn
              color="success"
              title="Agregar todos los Saldos"
              @click="agregarTodos()"
              class="ml-4"
            >  Agregar todos </v-btn>
          </v-col>
        </v-row >
        <!-- Datos fijos -->
        <div class="d-flex justify-center">
          <v-data-table
            order-by="forma_cobro_nombre"
            :headers="nuevo ? hearder : [
              { text: 'Forma de pago', value: 'forma_cobro_nombre', align: 'start', formatter: formatMoney },
              { text: 'Importe', value: 'importe', align: 'end', formatter: formatMoney }
            ]"
            :items="saldos_usados"
            class="cebra mt-6 ma-6 elevation-2"
            hide-default-footer
            dense
            :style="$vuetify.breakpoint.xs ? 'width: 100%;' : 'width:40%;'"
            :key="key"
          >
            <template v-slot:no-data>
              <v-alert
                class="mx-auto mt-4"
                max-width="400"
                type="warning"
                border="left"
                dense
                text
              >
                No hay datos de saldos registrados
              </v-alert>
            </template>
            <!-- ultima row para el total -->
            <template slot="body.append">
              <tr class="font-weight-bold">
                <th style="font-size: 1em; " class="text-left"> Total Saldos </th>
                <th style="font-size: 1em; " class="text-right" colspan="2">{{ formatMoney(retornaSaldosUsados) }}</th>
              </tr>
            </template>
            <template v-slot:[`item.importe`]="{item}">
              {{formatMoney(item.importe)}}
            </template>
            <template v-slot:[`item.acciones`]="{item}" v-if="nuevo">
              <v-btn icon color="error" title="Eliminar" @click="eliminarSaldo(item)">
                <v-icon small>
                  fas fa-times
                </v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </div>
      </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</div>
</template>

<script>
import { format_money, order_list_by } from '../../util/utils'
import TextFieldMoneyVue from '../util/TextFieldMoney.vue'

export default {
  name: 'SaldosFavorUtilizados',
  props:{
    datos:{
      type: Object,
      default: {}
    }
  },
  data(){
    return{
      key:0,
      formatMoney: format_money,
      hearder:[
        { text: 'Forma de pago', value: 'forma_cobro_nombre', align: 'start', formatter: format_money },
        { text: 'Importe', value: 'importe', align: 'end', formatter: format_money },
        { text: 'Acción', value: 'acciones' ,align: 'end'}
      ],
      filtro:{
        importe: null,
        entidad_id:null,
      },
    }
  },
  created(){

  },
  methods:{
    agregarImporteSaldo(){
      if(!this.filtro.importe || this.filtro.entidad_id == null || this.filtro.entidad_id == undefined){
        this.$swal.fire({
          icon: 'error',
          title: `¡Datos faltantes!`,
          text: 'Se requiere ingresar Importe y un Tipo de deposito disponible.'
        })
        this.filtro.importe = null;
        this.filtro.entidad_id = null;
        return
      }
      if(this.filtro.entidad_id == 0){
        const aux2 = this.saldos_usados.filter(elem => elem.forma_cobro_id == this.filtro.entidad_id);
        const pos = this.saldos_usados.map(elem => elem.forma_cobro_id).indexOf(this.filtro.entidad_id)
        if(pos == -1){
          const aux  = this.entidades_deposito.filter(elem => elem.codigo == this.filtro.entidad_id)
          this.saldos_usados.push({ forma_cobro_nombre: aux[0].nombre, forma_cobro_id: this.filtro.entidad_id, importe: this.filtro.importe });
          this.filtro.importe = null;
          this.filtro.entidad_id = null;
        }else{
          this.saldos_usados[pos].importe += this.filtro.importe;
          this.filtro.importe = null;
          this.filtro.entidad_id = null;
        }
      }else{
        const aux = this.saldos_a_favor.filter(elem => elem.entidad_id == this.filtro.entidad_id)
        if(aux.length == 1){
          if(this.filtro.importe <= aux[0].saldo){
            const aux2 = this.saldos_usados.filter(elem => elem.forma_cobro_id == this.filtro.entidad_id);
            const pos = this.saldos_usados.map(elem => elem.forma_cobro_id).indexOf(this.filtro.entidad_id);
            if(pos == -1/*aux2.length == 0*/){
              let pos = this.saldos_a_favor.indexOf(aux[0]);
              this.saldos_a_favor[pos].saldo -= this.filtro.importe;
              this.saldos_a_favor[pos].saldo = parseFloat(this.saldos_a_favor[pos].saldo.toFixed(2));
              this.saldos_usados.push({ forma_cobro_nombre: aux[0].entidad_nombre, forma_cobro_id: this.filtro.entidad_id, importe: this.filtro.importe });
              this.filtro.importe = null;
              this.filtro.entidad_id = null;
            }else{
              this.saldos_usados[pos].importe += this.filtro.importe;
              this.filtro.importe = null;
              this.filtro.entidad_id = null;
            }
          }else{
            this.$swal.fire({
              icon: 'error',
              title: `Error con el importe ingresado`,
              text: 'El importe ingresado supera el saldo disponible.'
            })
          }
        }else{
          this.$swal.fire({
            icon: 'error',
            title: `No posee saldo disponible.`,
          })
        }
      }
      order_list_by(this.saldos_usados, 'forma_cobro_nombre')
    },
    eliminarSaldo(item){
      this.$emit('eliminarSaldo', item);
      this.key++;
    },
    agregarTodos(){
      let acu = 0;
      this.saldos_a_favor.forEach(elem => acu += elem.saldo);
      if(acu > 0){
        let nom;
        let id;
        let plata;
        let pos;
        for(let i in this.saldos_a_favor){
          nom = this.saldos_a_favor[i].entidad_nombre;
          id = this.saldos_a_favor[i].entidad_id;
          plata = this.saldos_a_favor[i].saldo;

          pos = this.saldos_usados.map(elem => elem.forma_cobro_id).indexOf(id)
          if(pos == -1){
            this.saldos_usados.push({ forma_cobro_nombre: nom, forma_cobro_id: id, importe: plata });
          }else{
            this.saldos_usados[pos].importe += plata;
          }

          this.saldos_a_favor[i].saldo = 0;
          this.saldos_a_favor[i].saldo = parseFloat(this.saldos_a_favor[i].saldo.toFixed(2));
        }
      }
    },
  },
  computed:{
    saldos_usados: {
      get(){
        return this.datos.saldos_usados;
      },
    },
    saldos_a_favor: {
      get(){
        return this.datos.saldos_a_favor;
      }
    },
    entidades_deposito:{
      get(){
        return this.datos.entidades_deposito;
      }
    },
    nuevo: {
      get(){
        return this.datos.nuevo;
      }
    },
    retornaSaldosUsados(){
      let acu = 0;
      this.saldos_usados.forEach(elem => acu += elem.importe)
      return parseFloat(acu.toFixed(2));
    },
    retornarSaldoDisponible(){
      let acu = 0;
      this.saldos_a_favor.forEach(elem => acu += elem.saldo)
      return parseFloat(acu.toFixed(2));
    }
  },
  components:{
    TextFieldMoneyVue
  },
  watch:{
/*    'datos.saldos_usados':{
      handler: function () {
        if (this.datos.saldos_usados.length != 0) {
          this.saldos_dt = this.datos.saldos_usados
        }
      },
      deep: true
    }*/
  }
}
</script>

<style>

</style>